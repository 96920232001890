<script setup>
import { watch, ref, onMounted } from 'vue';
import AppTopbar from './AppTopbar.vue';
// import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import { useLayout } from '@/layout/composables/layout';
import ToastList from "@/components/toast/ToastList.vue";
import GlobalLoading from '@/components/GlobalLoading.vue';
import { initFlowbite } from 'flowbite'


const { layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

onMounted(() => {
    initFlowbite();

});

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
    <div class="layout-wrapper">
        <app-topbar></app-topbar>
        <div class="layout-sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="layout-main-container md:container md:mx-auto lg:container ">
            <div class="p-4 mt-20 sm:ml-64 ">
                <router-view></router-view>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>

        <div class="layout-mask "></div>
    </div>
    <ToastList />
    <!-- <Toast /> -->
    <GlobalLoading />
</template>

<style lang="scss" scoped></style>
