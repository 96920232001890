<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { RouterLink, useRouter } from 'vue-router';

import logo from '@/assets/logo.png'
import Dashboard from '@/views/Dashboard.vue';
import { initFlowbite } from 'flowbite'
import { FwbAvatar } from 'flowbite-vue'

const { layoutConfig, onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();

onMounted(() => {
    initFlowbite();
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const logoUrl = computed(() => {
    return `/layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
});

const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
    topbarMenuActive.value = false;
    router.push('/documentation');
};
const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const showProfileMenu = ref(false);

const logout = () => {
    $cookies.remove(import.meta.env.VITE_COOKIE_NAME);
    localStorage.removeItem('actions');
    localStorage.removeItem('level');
    localStorage.removeItem('status');
    localStorage.removeItem('sespi_email');
    localStorage.removeItem('sespi_user');
}

const email = localStorage.getItem('sespi_email')
const user = localStorage.getItem('sespi_user')
</script>

<template>
    <nav class="fixed top-0 z-50 w-full bg-primary border-b border-primary dark:bg-gray-800 dark:border-gray-700">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center justify-start rtl:justify-end">
                    <button data-drawer-target="drawer-navigation" data-drawer-show="drawer-navigation"
                        aria-controls="drawer-navigation" type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span class="sr-only">Abrir menú</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd"
                                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                            </path>
                        </svg>
                    </button>
                    <router-link :to="{ name: 'dashboard' }" class="flex ms-2 md:me-24">
                        <img :src="logo" class="h-8 me-3" alt="FlowBite Logo" />
                    </router-link>
                </div>
                <div class="flex flex-col items-center mr-11">
                    <div class="block flex-col items-center ms-3">
                        <div>
                            <button type="button" @click="showProfileMenu = !showProfileMenu"
                                class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded="false">
                                <span class="sr-only">Open user menu</span>
                                <fwb-avatar rounded />
                            </button>
                        </div>
                        <div v-if="showProfileMenu"
                            class="z-40 fixed left-0 my-4 mr-6 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 "
                            style="inset: 50px 1px auto auto !important;" id="dropdown-user-menu">
                            <div class="px-4 py-3" role="none">
                                <p class="text-sm text-gray-900 dark:text-white" role="none">
                                    {{ user }}
                                </p>
                                <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                                    {{ email }}
                                </p>
                            </div>

                            <ul class="py-1" role="none">
                                <li>
                                    <RouterLink :to="{ name: 'account' }" @click="showProfileMenu = !showProfileMenu"
                                        class=" block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                                        dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem">Cuenta</RouterLink>
                                </li>
                                <li>
                                    <a href="#" @click="logout"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem">Cerrar sesión</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped></style>
