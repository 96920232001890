import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue';
import { pinia } from '@/main'
import { useActionsStore } from '@/stores/actions';
// import { storeToRefs } from "pinia";


// const { actions } = storeToRefs(useActions);
// const { checkPermission } = useActions;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/survies/Home.vue')
    },
    {
      path: '/sistema/empresas/dashboard/',
      // path: '/',
      component: AppLayout,
      children: [

        {
          path: '/sistema/empresas/dashboard/',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue')
        },
        {
          path: '/sistema/empresas/cuestionarios/',
          name: 'survies',
          component: () => import('@/views/Survies.vue'),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },
        },
        {
          path: '/sistema/empresas/cuestionarios/preguntas/:survey',
          name: 'questions',
          component: () => import('@/views/Questions.vue'),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },
        },
        {
          path: '/sistema/empresas/cuestionarios/preguntas/:survey/nueva',
          name: 'add_question',
          component: () => import('@/views/NewQuestion.vue'),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },
        },
        {
          path: '/sistema/empresas/cuestionarios/preguntas/:survey/respuestas/:question',
          name: 'answers',
          component: () => import('@/views/Answers.vue'),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },
        },
        {
          path: '/sistema/empresas/usuarios',
          name: 'users',
          component: () => import('@/views/Users.vue'),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },

        },
        {
          path: '/sistema/empresas/reporte/individual',
          name: 'individual_report',
          component: () => validateLevelReport(),
          beforeEnter: (to, from) => {
            if (!validateAccess([1])) return { name: 'dashboard' }
          },
        },
        {
          path: '/sistema/empresas/reporte/descargas',
          name: 'download_files',
          component: () => import('@/views/reports/DownloadFiles.vue'),
        },
        {
          path: '/sistema/empresas/cuenta',
          name: 'account',
          component: () => import('@/views/Account.vue'),
        },
        {
          path: '/sistema/empresas/revision',
          name: 'account',
          component: () => import('@/views/reports/Revision.vue'),
        },
        {
          path: '/sistema/empresas/reporte/corporativo',
          name: 'account',
          component: () => import('@/views/reports/Corportae.vue'),
        },

      ]
    },

    {
      path: '/sistema/empresas/',
      component: () => import('@/views/auth/Login.vue')
    },
    {
      path: '/pages/notfound',
      name: 'notfound',
      component: () => import('@/views/error/NotFound.vue')
    },
    {
      path: '/contesta/cuestionario/:catalog/:code',
      name: 'survey_user_with_catalog',
      component: () => import('@/views/survies/Survey.vue'),
      beforeEnter: (to, from, next) => {
        if (!to.params.code && !to.params.catalog) {
          next({ name: 'notFound' })
        }
        next();
      }
    },
    {
      path: '/contesta/cuestionario/:code',
      name: 'survey_user',
      component: () => import('@/views/survies/Survey.vue'),
      beforeEnter: (to, from, next) => {
        if (!to.params.code) {
          next({ name: 'notFound' })
        }
        next();
      }
    },

    {
      path: '/sistema/empresas/auth/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue')
    },
    {
      path: '/sistema/empresas/auth/login/change_password',
      name: 'change_password',
      component: () => import('@/views/auth/ChangePassword.vue')
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: () => import('@/views/survies/Thanks.vue')
    },
    {
      path: '/comingsoon',
      name: 'error',
      component: () => import('@/views/survies/ComingSoon.vue')
    },

    {
      path: '/integracion/:type/:hash/:data',
      name: 'appReport',
      component: () => import('@/views/reports/integrations/app/DefaultReport.vue')
    },


    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () => import('@/views/error/NotFound.vue')
    }
  ]
})

const isAuthenticated = false;


router.beforeEach((to, from, next) => {
  let useActions = useActionsStore();
  let { shouldChangePassword } = useActions;

  if (to.name === 'change_password' && !shouldChangePassword()) {
    return next({ name: 'dashboard' })
  }

  if (to.name === 'login' && hasAuthenticate()) {
    return next({ name: 'dashboard' })
  }

  if (hasAuthenticate() && shouldChangePassword() && to.path.includes('/sistema/empresas/') && to.name !== 'change_password') {
    return next({ name: 'change_password' });
    // if () {
    // }
  } else if (to.path.includes('/sistema/empresas/') && to.name !== 'login' && !hasAuthenticate()) {
    return next({ name: 'login' });
  }

  next();
});

const hasAuthenticate = () => {

  return $cookies.isKey('authtg-u')
}

const validateAccess = (actions) => {
  let useActions = useActionsStore();
  let { checkPermission } = useActions;
  return checkPermission(actions)
}

const validateLevelReport = () => {
  const useActions = useActionsStore();
  const { isPremiumAccount } = useActions;


  if (isPremiumAccount()) return import('@/views/reports/IndividualReportPremium.vue');

  return import('@/views/reports/IndividualReport.vue')
}

const validateLevelAppReport = (to) => {
  console.log(to);
  if (to) return import('@/views/reports/IndividualReportPremium.vue');

  return import('@/views/reports/IndividualReport.vue');
}

export default router
