<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        items: [
            { label: 'Inicio', icon: 'pi pi-fw pi-home', to: { name: 'dashboard' }, actions: [1, 2] },
            { label: 'Cuestionarios', icon: 'pi pi-fw pi-home', to: { name: 'survies' }, actions: [1] },
            {
                label: 'Reportes',
                icon: 'pi pi-fw pi-home',
                items: [
                    { label: 'Reporte individual', icon: 'pi pi-fw pi-home', to: { name: 'individual_report' }, s: true, actions: [1, 2] },
                    // { label: 'Reporte corporativo', icon: 'pi pi-fw pi-home', to: { name: 'dashboard' }, s: true, actions: [1, 2] },
                    // { label: 'Reporte de cierre de ciclo', icon: 'pi pi-fw pi-home', to: { name: 'dashboard' }, s: true, actions: [1, 2] },
                    { label: 'Descargas', icon: 'pi pi-fw pi-home', to: { name: 'download_files' }, s: true, actions: [1, 2] },
                ],
                actions: [1, 2]
            },
            { label: 'Administración de usuarios', icon: 'pi pi-fw pi-home', to: { name: 'users' }, actions: [1] },

        ]
    },
]);
</script>

<template>
    <aside id="drawer-navigation"
        class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-primary border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto bg-primary dark:bg-gray-800">
            <ul class="space-y-2 font-medium">
                <template v-for="(item, i) in model" :key="item">
                    <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
                    <li v-if="item.separator" class="menu-separator"></li>
                </template>
            </ul>
        </div>
    </aside>



    <!-- <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>

</ul> -->
</template>

<style lang="scss" scoped></style>
