import './index.css'
import './assets/custom.css'
// import './assets/fw-style.css'
import '../node_modules/flowbite-vue/dist/index.css'
import { useActionsStore } from '@/stores/actions'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'

import { initFlowbite } from 'flowbite'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faPlus,
    faEdit,
    faTrash,
    faChevronRight,
    faStar,
    faQrcode,
    faAngleDown,
    faEye,
    faEyeSlash,
    faFileExport
} from '@fortawesome/free-solid-svg-icons'

import { far } from '@fortawesome/free-regular-svg-icons';


library.add(far, faPlus, faEdit, faTrash, faChevronRight, faStar, faQrcode, faAngleDown, faEye, faEyeSlash, faFileExport);

export const pinia = createPinia()

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

initFlowbite();
app.use(VueCookies, { expires: '1d' })

app.use(pinia)
app.use(router)

const actiosStore = useActionsStore();

app.mount('#app')
