import { reactive } from "vue";

export default reactive({
    loading: false,
    showLoading() {
        this.loading = true
    },
    removeLoading() {
        this.loading = false
    }
});