import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useActionsStore = defineStore('actions', () => {
    const actions = ref([]);
    const level = ref(0);
    const status = ref(1);

    if (localStorage.getItem("actions")) {
        actions.value = localStorage.getItem("actions");
    }

    if (localStorage.getItem("level")) {
        level.value = localStorage.getItem("level");
    }

    if (localStorage.getItem("status")) {
        status.value = localStorage.getItem("status");
    }

    const add = (value) => {
        actions.value = value;
        localStorage.setItem("actions", value);
    }

    const setLevel = (value) => {

        level.value = value;
        localStorage.setItem("level", value);
    }

    const setStatus = (value) => {

        status.value = value;
        localStorage.setItem("status", value);
    }

    const checkPermission = (action) => {
        let hasPermission = false;
        action.forEach(element => {
            if (actions.value.includes(element)) {
                hasPermission = true;
            }
        });
        return hasPermission;
    }

    const isPremiumAccount = () => {
        if (level.value == '1') return true
        return false;
    }

    const shouldChangePassword = () => {

        return status.value == -1 || status.value == '-1'
    }

    return { actions, add, checkPermission, setLevel, isPremiumAccount, level, status, setStatus, shouldChangePassword }
});